import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

import RegistrationUl from "../views/registration-ul.vue";
import RegistrationClient from "../views/registration-client.vue";
import NewDivision from "../views/new-division.vue";
import SingleDivision from "../views/single-division.vue";
import SingleDivisionMain from "../views/single-division-main.vue";
import Branches from "../views/branches.vue";
import DivisionUsers from "../views/division-users.vue";
import CompareBd from "../views/compare_bd/index.vue";
import Serv from "../views/serv.vue";
import Policy from "../views/policy/policy.vue";

import UserRoutes from './user'
import BillingRoutes from './billing'
import NsiRoutes from './nsi'
import TnvedRoutes from './tnved'
import StatisticsRoutes from "./statistics";
import JournalRoutes from "./journal";
import TransitRoutes from "./transit";
import EpiRoutes from './epi';
import RsRoutes from "./rs";
import DtRoutes from "./dt";
import DtsRoutes from "./dts";
import UvrRoutes from "./uvr"
import PassengerRoutes from "./passenger";
import CatalogsRoutes from "./catalogs";
import ReportsRoutes from "./reports";
import SettingsRoutes from "./settings";
import AutoRoutes from "./auto";
import NotificationRoutes from "./notification";
import EnsuringRoutes from "./ensuring"
import PaymentOrdersRouts from "./payment-orders"
import ZvtRoutes from "./zvt";
import KdtRoutes from "./kdt";

import VideoGuides from "../views/guides/video-guides.vue";

import Error from "../views/error.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/compare-database",
    component: CompareBd,
  },

  {
    path: "/registration-ul",
    component: RegistrationUl,
  },
  {
    path: "/registration-client/:id",
    component: RegistrationClient,
    props: true,
  },
  {
    path: "/new-division",
    component: NewDivision,
    meta: {
      requiresAuth: true,
      permissions: ["organization.div.update"],
    },
  },
  {
    path: "/new-division/:id",
    component: SingleDivisionMain,
    props: true,
    meta: {
      permissions: ["organization.div.update"],
    },
    children: [
      {
        path: "/",
        component: SingleDivision,
        name: "Информация",
        props: true,
      },
      {
        path: "/new-division/:id/branches",
        component: Branches,
        name: "Филиалы",
        props: true,
      },
      {
        path: "/new-division/:id/users",
        component: DivisionUsers,
        name: "Пользователи",
        props: true,
      },

    ],
  },
  {
    path: "/services",
    component: Serv,
  },

  ...UserRoutes,
  ...BillingRoutes,
  ...NsiRoutes,
  ...TnvedRoutes,
  ...StatisticsRoutes,
  ...TransitRoutes,
  ...RsRoutes,
  ...PassengerRoutes,
  ...JournalRoutes,
  ...CatalogsRoutes,
  ...ReportsRoutes,
  ...SettingsRoutes,
  ...AutoRoutes,
  ...NotificationRoutes,
  ...EnsuringRoutes,
  ...EpiRoutes,
  ...DtRoutes,
  ...DtsRoutes,
  ...UvrRoutes,
  ...PaymentOrdersRouts,
  ...ZvtRoutes,
  ...KdtRoutes,
  {
    path: "/privacy-policy",
    component: Policy,
  },
  {
    path: "/video-guides",
    component: VideoGuides,
  },
  {
    path: "/403",
    component: () => import("@/views/access-denied.vue"),
  },
  {
    path: "*",
    component: Error,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const $can = (string) => {
  if (string) {
    const authorities = store.getters["auth/getAuthorities"];
    return authorities.includes(string);
  }
  return false;
};
const $allowed = (rules = []) => {
  const res = rules.map((permission) => $can(permission));
  return !res.includes(false);
};
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Веб-Декларант";
  const route = to.matched.find((record) => record.meta?.permissions?.length);
  if (route && !$allowed(route.meta.permissions)) {
    next("/403");
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/getUser"]) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

// Catch route errors
['push','replace'].forEach(method => {
  const originalMethod = VueRouter.prototype[method];
  VueRouter.prototype[method] = function m(location) {
    return originalMethod.call(this, location).catch((err) => {
      if(!['NavigationDuplicated'].includes(err.name )) throw err
    })
  };
});
export default router;
