import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import createMutationsSharer from "vuex-shared-mutations";
Vue.use(Vuex)

import ui from './ui'
import auth from '../store/auth'
import tnved from '../store/tnved'
import nsi from '../store/nsi'
import rs from '../store/rs'
import catalogs from '../store/catalogs'
import journal from '../store/journal'
import statistics from '../store/statistics'
import billing from '../store/billing'
import division from '../store/division'
import currency from '../store/currency'
import documents from '../store/documents/common'
import experiment from './experiment'
import transit from './transit'
import ensuring from './ensuring'
import epi from './epi'
import ptd from './ptd'
import sez from "./sez";
import dt from './dt'
import dts from './dts'
import dtSettings from "./dt-settings";
import zvt from "./zvt"
import zvtSettings from "./zvt-settings";
import kdt from './kdt'
import kdtSettings from "./kdt-settings";
import uvr from './uvr'
import notification from "@/store/notification";
import buffer from "@/store/buffer";

const persistedState = createPersistedState({
  paths:['catalogs','experiment','auth', 'journal', 'buffer', 'dtSettings']
})

export default new Vuex.Store({
  modules: {
    ui,
    auth,
    tnved,
    rs,
    nsi,
    statistics,
    catalogs,
    billing,
    division,
    currency,
    journal,
    documents,
    experiment,
    transit,
    ensuring,
    epi,
    ptd,
    sez,
    notification,
    buffer,
    dt,
    dts,
    uvr,
    dtSettings,
    zvt,
    zvtSettings,
    kdt,
    kdtSettings,
  },
  plugins:[
    persistedState,
    createMutationsSharer({
      predicate: (mutation) => {
        return ['buffer/', 'auth/', 'journal/'].some(pattern => mutation.type.startsWith(pattern))
      }
    })
  ]
})
