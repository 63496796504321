import axios from "axios";
import {CATALOGS, TNVED, UVR} from "@/http-routes";

import documentMutations from "./utils/document-mutations";
import shipmentMutations from "@/store/utils/shipment-mutations";

import documentActionsFactory from "./utils/document-actions";
import shipmentActionsFactory from "./utils/shipment-actions";

const documentActions = documentActionsFactory(UVR);
const shipmentActions = shipmentActionsFactory(UVR)
export default {
  namespaced: true,
  state: {
    selected: {},
    control: [],
    selectedWares: [],
    editableWare: {id: null, index: null},
    selectedId: null,
    aeos: [],
    nsiTransportMarks: [],
    requests: [],
    shipmentIndex: null,
  },
  mutations: {
    ...documentMutations,
    ...shipmentMutations,
    // Справочник
    SET_NSI_AEOS(state, aeos) {
      state.aeos = aeos;
    },
    SET_NSI_TRANSPORT_MARKS(state, nsiTransportMarks) {
      state.nsiTransportMarks = nsiTransportMarks;
    },

    SET_EDITABLE_WARE(state, id) {
      state.editableWare = id;
    },
    SET_WARES(state, wares) {
      state.selectedWares = wares
    },
    // ОБНОВИТЬ ТРАНСПОРТ
    UPDATE_TRANSPORT_IN_DECLARATION(state, items) {
      if (items.length === state.selected.transports.length) {
        state.selected.transports.splice(
          0,
          state.selected.transports.length,
          ...items
        );
        console.log("Все ТС обновлены");
      } else {
        items.forEach((item) => {
          const {id} = item;
          const current = state.selected.transports.find((i) => i.id === id);
          const index = state.selected.transports.indexOf(current);
          console.log("STORE UPDATE DOC idx >>", index);
          state.selected.transports.splice(index, 1, item);
        });
      }
    },
    // ДОБАВИТЬ ТРАНСПОРТ
    SET_NEW_TRANSPORT(state, item) {
      state.selected.transports.push(item);
    },
    // УДАЛИТЬ ТРАНСПОРТ
    DELETE_TRANSPORT(state, payload) {
      const current = state.selected.transports.find((i) => i.id === payload);
      const index = state.selected.transports.indexOf(current);
      state.selected.transports.splice(index, 1);
    },
    // ПЕРЕГРУЗКИ
    CREATE_RELOAD(state, payload) {
      state.selected.reloads.push(payload);
    },
    UPDATE_RELOADS(state, payload) {
      state.selected.reloads = payload;
    },
    UPDATE_RELOAD(state, payload) {
      const index = state.selected.reloads.findIndex((i) => i.id === payload.id);
      state.selected.reloads.splice(index, 1, payload);
    },
    // ДОБАВИТЬ ТРАНСПОРТ К ПЕРЕГРУЗКЕ
    CREATE_RELOAD_TRANSPORT(state, payload) {
      const current = state.selected.reloads.find(
        (i) => i.id === payload.reload_id
      );
      current.transports.push(payload);
    },
    // УДАЛИТЬ ТРАНСПОРТ ИЗ ПЕРЕГРУЗКИ
    DELETE_RELOAD_TRANSPORT(state, payload) {
      const current = state.selected.reloads.find((i) => i.id === payload.id);
      const deleted_transport = current.transports.find(
        (i) => i.id === payload.transport_id
      );
      const index = current.transports.indexOf(deleted_transport);
      current.transports.splice(index, 1);
    },
    //  ОБНОВИТЬ ДОКУМЕНТ
    UPDATE_DOCUMENT_IN_DECLARATION(state, items) {
      if (items.length === state.selected.presented_documents.length) {
        state.selected.presented_documents.splice(
          0,
          state.selected.presented_documents.length,
          ...items
        );
        console.log("all docs updated");
      } else {
        items.forEach((item) => {
          const {id} = item;
          const current = state.selected.presented_documents.find(
            (i) => i.id === id
          );
          const index = state.selected.presented_documents.indexOf(current);
          console.log("STORE UPDATE DOC idx >>", index);
          state.selected.presented_documents.splice(index, 1, item);
        });
      }
    },
  },
  actions: {
    ...documentActions,
    ...shipmentActions,
    // ОБНОВИТЬ ТРАНСПОРТ
    updateTransport({commit}, {payload}) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios
        .post(`${UVR}transport/updateAll`, data)
        .then(() => {
          commit("UPDATE_TRANSPORT_IN_DECLARATION", data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // ДОБАВИТЬ ТРАНСПОРТ
    createTransport({commit, state}) {
      axios
        .post(`${UVR}transport/create?declarationId=${state.selected.id}`)
        .then((res) => {
          commit("SET_NEW_TRANSPORT", res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // УДАЛИТЬ ТРАНСПОРТ
    deleteTransport({commit, state}, payload) {
      const data = Array.isArray(payload) ? payload : [payload];
      axios
        .post(
          `${UVR}transport/deleteFromDeclaration?declarationId=${state.selected.id}`,
          data
        )
        .then(() => {
          commit("DELETE_TRANSPORT", payload);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // ДОБАВИТЬ ПЕРЕГРУЗКУ
    createReload({commit, state}) {
      axios
        .post(`${UVR}reload/create?declarationId=${state.selected.id}`)
        .then((res) => {
          commit("CREATE_RELOAD", res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // УДАЛИТЬ ПЕРЕГРУЗКУ
    deleteReload({commit, state}, payload) {
      commit("UPDATE_RELOADS");
      const data = Array.isArray(payload) ? payload : [payload];
      axios
        .post(
          `${UVR}reload/deleteByIds?declarationId=${state.selected.id}`,
          data
        )
        .then((res) => {
          commit("UPDATE_RELOADS", res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // ОБНОВИТЬ ПЕРЕГРУЗКУ
    updateReload({commit}, {payload}) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios
        .post(`${UVR}reload/updateAll`, data)
        .then((res) => {
          commit("UPDATE_RELOAD", payload);
          return res
        })
    },
    // ДОБАВИТЬ ТРАНСПОРТ К ПЕРЕГРУЗКЕ
    createReloadTransport({state}, id) {
      return axios
        .post(
          `${UVR}transport/create?declarationId=${state.selected.id}&reloadId=${id}`
        )
    },
    // УДАЛИТЬ ТРАНСПОРТ ИЗ ПЕРЕГРУЗКИ
    deleteReloadTransport(_, payload) {
      const data = Array.isArray(payload.transport_id)
        ? payload.transport_id
        : [payload.transport_id];
      return axios
        .post(`${UVR}transport/deleteFromReload?reloadId=${payload.id}`, data)
    },
    copyWareDetail(_, payload) {
      return axios.post(
        `${UVR}wareDetails/copyWareDetails?id=${payload.id}&sortIndex=${payload.index}`
      );
    },
    fetchNsiAeos({commit}) {
      axios.get(`${CATALOGS}nsiAeos`).then((res) => {
        commit("SET_NSI_AEOS", res.data.list);
      });
    },
    fetchNsiTransportMarks({commit}) {
      return axios.get(`${CATALOGS}nsiTransportMarks`).then((res) => {
        const result =
          res.data?.list.map((i) => {
            return {
              ...i,
              text: [i.code, i.name].join("-"),
              search: [i.code, i.name].join("-").toLowerCase(),
            };
          }) || [];
        commit("SET_NSI_TRANSPORT_MARKS", result);
      });
    },
    //
    getAvailableExcise(_, payload) {
      if (!payload.tn_ved) Promise.reject(new Error("Отсутствует код ТНВЭД"));
      const promise = new Promise((resolve, reject) => {
        axios
          .get(
            `${TNVED}api/read/nsiTransitExcise/getByTnVed?tnVed=${payload.tn_ved}`
          )
          .then(({data}) => {
            if (!data.length) {
              if (!payload.date) {
                let error = new Error(
                  "Отсутствует дата для загрузки акциза из таможни"
                );
                error.color = "orange";
                throw error;
              }
              axios
                .get(
                  `${TNVED}api/read/getExcisesByTnVed?tnVed=${payload.tn_ved}&declarationDate=${payload.date}`
                )
                .then(({data}) => {
                  resolve({data, type: 2});
                })
                .catch((err) => reject(err));
            } else {
              resolve({data, type: 1});
            }
          })
          .catch((e) => reject(e));
      });
      return promise;
    },
    calculatePayments({state}) {
      return axios.post(
        `${UVR}declaration/calculatePayments?id=${state.selected.id}`
      );
    },
    validatePayments({state, commit}) {
      return axios
        .get(`${UVR}declaration/validatePayments?id=${state.selected.id}`)
        .then((res) => {
          if (res.data) {
            commit("documents/SET_PAYMENT_ERRORS", res.data, {root: true});
          }
          return res;
        });
    },
    getXml(_, id) {
      return axios.get(`${UVR}declaration/export?declarationId=${id}`);
    },
    performControlNCES(_, id) {
      if (!id) return Promise.reject(new Error("Отсутствует номер документа"));
      return axios.get(`${UVR}declaration/flk?declarationId=${id}`);
    },
    sendToEclient(_, {xml, declarationId, userId, divisionId, ptoNumber}) {
      return axios.post(
        `${UVR}declaration/sendToEclient?userId=${userId}&divisionId=${divisionId}&declarationId=${declarationId}&ptoId=${ptoNumber}`,
        xml,
        {
          headers: {
            "Content-Type": "application/xml",
          },
        }
      );
    },
    fetchGoods(_, id) {
      return axios.get(`${UVR}ware/getByDeclarationId?declarationId=${id}`);
    },
    copyDocument({rootState}, {id, copyDocuments, wareIds, copyAllWares, copyGuarantees}) {
      const uid = rootState?.auth?.user?.user?.id;
      if (!uid) return Promise.reject(new Error("Отсутствует пользователь"));
      if (!id) return Promise.reject(new Error("Отсутствует ID документа"));
      let url =
        `${UVR}declaration/copyDeclaration?declarationToCopyId=${id}` +
        `&copyDocuments=${copyDocuments}` +
        `&userId=${uid}` +
        `&copyAllWares=${copyAllWares}` +
        `&copyGuarantees=${copyGuarantees}`
      ;
      return axios.post(url, wareIds);
    },
    uploadExcel(
      {dispatch, state},
      {formData, id, wareShipmentId, overwrite, joinArticleDescription}
    ) {
      return axios
        .post(
          `${UVR}declaration/uploadXlsx?declarationId=${id}&wareShipmentId=${wareShipmentId}` +
          `&joinArticleDescription=${joinArticleDescription}` +
          `&overwrite=${overwrite}`,
          formData
        )
        .then((res) => {
          dispatch('getDocumentById', state.selected.id)
          // commit("REPLACE_BLOCK_IN_DECLARATION", res.data);
          return res;
        });
    },
    downloadTransportDeclaration(_, {
      id, items, common
    }) {
      return axios.post(`${UVR}declaration/tdtsPdf?id=${id}&commonTdts=${common}`, items, {
        responseType: 'blob'
      })
    },
    getRevokeXml({rootState}, {eclientRequestId}) {
      const uid = rootState?.auth?.user?.user?.id;
      return axios.get(`${UVR}declaration/getRevokeXml?userId=${uid}&eclientRequestId=${eclientRequestId}`)
    },
    revokeDeclaration({rootState}, {declarationId, guid, xml}) {
      const uid = rootState?.auth?.user?.user?.id;
      return axios.post(`${UVR}declaration/revoke?userId=${uid}&guid=${guid}&declarationId=${declarationId}`, xml)
    },
    getWarrantyApplication(_, {declarationId, payload}) {
      return axios.post(`${UVR}declaration/zsoPdf?id=${declarationId}`, payload, {
        responseType: 'blob'
      })
    },
    getForwardingOrder(_, {declarationId, userId, divisionId, payload}) {
      return axios.post(`${UVR}declaration/PegUEOPdf?userId=${userId}&id=${declarationId}&divisionId=${divisionId}`, payload, {
        responseType: 'blob'
      })
    },
    createPassWithForm({rootState}, payload) {
      const uid = rootState?.auth?.user?.user?.id;
      return axios.post(`${UVR}pass/create?userId=${uid}`, payload)
    },
    createPassFromUvr({rootState}, declarationId) {
      const uid = rootState?.auth?.user?.user?.id;
      return axios.post(`${UVR}pass/create?userId=${uid}&declarationId=${declarationId}`)
    },
    createPassFromEpi({rootState}, epi) {
      const uid = rootState?.auth?.user?.user?.id;
      return axios.post(`${UVR}pass/createFromEpiList?userId=${uid}`, epi)
    },
    attachDocumentFilesToUvr({state}, formData) {
      const declarationId = state.selected.id
      return axios.post(`${UVR}presentedDocument/attachDocumentFilesToUvr?declarationId=${declarationId}`, formData)
    },
    getDocumentFilesNamesForUvr({state}) {
      const declarationId = state.selected.id
      return declarationId > 0 ? axios.get(`${UVR}presentedDocument/getDocumentFilesNamesForUvr?declarationId=${declarationId}`) : []
    },
    getDocumentFileForUvr({state}, documentFileName) {
      const declarationId = state.selected.id
      return axios.get(`${UVR}presentedDocument/getDocumentFileForUvr?documentFileName=${documentFileName}&declarationId=${declarationId}`, {
        responseType: "blob"
      })
    },
    deleteDocumentFileForUvr({state}, documentFileName) {
      const declarationId = state.selected.id
      return axios.delete(`${UVR}presentedDocument/deleteDocumentFileForUvr?documentFileName=${documentFileName}&declarationId=${declarationId}`)
    },
    getDocumentFilesForUvr({state}) {
      const declarationId = state.selected.id
      return axios.get(`${UVR}presentedDocument/getDocumentFilesForUvr?declarationId=${declarationId}`, {
        responseType: "blob"
      })
    }
  },
  getters: {
    getSelected: (s) => s.selected,
    getSelectedWares: (s) => s.selectedWares,
    getNsiAeos: (s) => s.aeos,
    getNsiTransportMarks: (s) => s.nsiTransportMarks,
    getRequests: (s) => s.requests,
    getControl: (s) => s.control,
    getShipmentIndex: (s) => s.shipmentIndex,
    getVisibility: (s) => !!s.selected?.registration_details?.reg_num_a,
    getEditableWare: (s) => s.editableWare
  },
};
