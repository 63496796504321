<template>
  <v-menu
    offset-y
    open-on-hover
  >
    <template #activator="{ on, attrs }">
      <v-btn
        class="mx-1"
        fab
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="#5CB7B1">
          mdi-currency-usd
        </v-icon>
      </v-btn>
    </template>
    <v-simple-table
      class="pt-2"
      dense
    >
      <thead>
        <tr>
          <th />
          <th class="subtitle-2">
            {{ currentDate }}
          </th>
          <th class="subtitle-2">
            {{ tomorrowDate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in items"
          :key="index"
          class="border-none"
        >
          <td class="font-weight-bold">
            {{ item.code }}
          </td>
          <td>{{ item.current }}</td>
          <td :class="getRateClass(item.current, item.tomorrow)">
            {{ item.tomorrow }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-menu>
</template>
<script>
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  data:() => ({
    items:[],
    currentDate:null,
    tomorrowDate:null
  }),
  computed:{
    ...mapGetters({
      rates:'currency/getRates'
    })
  },
  created() {
    const format = 'DD.MM'
    this.currentDate = moment().format(format)
    this.tomorrowDate = moment().add(1, "days").format(format)
    this.fetchSpecificRates()
  },
  methods:{
    getRateClass(current, tomorrow){
      return current !== tomorrow ? (tomorrow < current ? 'green--text':'red--text'): ''
    },
    fetchSpecificRates(){
      const date = moment().add(1, "days").format("YYYY-MM-DD")
      const currencies = ['USD', 'EUR', 'CNY','RUB']
      const currentRates = this.$store.dispatch('currency/fetchCurrentRates')
      const tomorrowRates = currencies.map((code) => {
        return this.$store.dispatch('currency/fetchRate', {code, date})
      })
      Promise.all([currentRates, ...tomorrowRates]).then(([currentResponse, ...tomorrowResponse]) => {
        const currentRates = currentResponse.data
        this.items = currencies.map((currency, index) => {
          const [tomorrow] = tomorrowResponse[index].data
          const current = currentRates.find(i => i.letter_code === currency)
          return {
            code: currency,
            current: current?.rate ?? "—",
            tomorrow: tomorrow?.rate ?? "—"
          }
        })
      })
    },
  }

}
</script>
<style scoped>

tr{
    border: none;
}

</style>